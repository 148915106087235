const $ = require('jquery');
global.$ = global.jQuery = $;

import 'bootstrap'
import 'vanilla-cookieconsent'
import 'animate.css';

require('vanilla-cookieconsent/dist/cookieconsent.css')
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.min.js');
require('../css/app.scss');

$(function () {
    $('[data-bs-toggle="popover"]').popover()
  })

$(function () {
    $('[data-bs-toggle="tooltip"]').tooltip()
})

// Cookieconsent
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: 'es',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {
        // ...
    },

    onChange: function (cookie, changed_preferences) {
        // ...
    },

    languages: {
        'es': {
            consent_modal: {
                title: '🍪 Cookies',
                description: 'Utilizamos cookies para el correcto funcionamiento de la web. <button type="button" data-cc="c-settings" class="cc-link">Elegir cookies</button>',
                primary_btn: {
                    text: 'Aceptar todas',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Rechazar todas',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Configuración',
                save_settings_btn: 'Guardar',
                accept_all_btn: 'Aceptar todas',
                reject_all_btn: 'Rechazar todas',
                close_btn_label: 'Cerrar',
                cookie_table_headers: [
                    {col1: 'Nombre'},
                    {col2: 'Dominio'},
                    {col3: 'Caducidad'},
                    {col4: 'Descripción'}
                ],
                blocks: [
                    {
                        title: 'Uso de las cookies 📢',
                        description: 'Utilizamos cookies para el propio funcionamiento de la web y para mejorar tu experiencia de usuario. Puedes elegir qué cookies podemos utilizar. Para más información, puedes leer nuestra <a href="#" class="cc-link">política de cookies</a>.'
                    }, {
                        title: 'Cookies estrictamente necesarias',
                        description: 'Cookies básicas para el correcto funcionamiento de la web. Sin ellas, no podrías navegar correctamente por ella.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                          {
                            col1: 'PHPSESSID',
                            col2: 'orlarus.com',
                            col3: 'Session',
                            col4: 'Cookie generated by applications based on the PHP language. This is a general purpose identifier used to maintain user session variables. It is normally a random generated number, how it is used can be specific to the site, but a good example is maintaining a logged-in status for a user between pages.',
                            is_regex: true
                          },
                          {
                            col1: '_GRECAPTCHA',
                            col2: 'orlarus.com',
                            col3: '6 meses',
                            col4: 'Google reCAPTCHA sets a necessary cookie (_GRECAPTCHA) when executed for the purpose of providing its risk analysis.',
                            is_regex: true
                          }
                        ]
                    }, {
                        title: 'Cookies de analíticas',
                        description: 'Permiten crear estadísticas con el fin de mejorar la experiencia de usuario.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '_gid',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '1 día',
                                col4: 'This cookie is set by Google Analytics. It stores and update a unique value for each page visited and is used to count and track pageviews.',
                                is_regex: true
                            },
                            {
                                col1: '_gat',
                                col2: 'google.com',
                                col3: '1 minuto',
                                col4: 'This cookie name is associated with Google Universal Analytics, according to documentation it is used to throttle the request rate - limiting the collection of data on high traffic sites.',
                            },
                            {
                                col1: '_ga',
                                col2: 'google.com',
                                col3: '2 años',
                                col4: "This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.",
                            }
                        ]
                    }, {
                        title: 'Publicidad y segmentación',
                        description: 'Datos de segmentación para mostrarte publicidad acorde a tus intereses. Los datos son anónimos.',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    }, {
                        title: 'Más información',
                        description: 'Para cualquier consulta sobre la política de cookies, puedes <a class="cc-link" href="#contacto">contactarnos</a>.',
                    }
                ]
            }
        }
    }
});